import React, { useState, useEffect } from 'react';
import { Container, CssBaseline, Typography, Box, Grid, Button, Snackbar } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ShareIcon from '@mui/icons-material/Share';
import InputForm from './components/InputForm';
import ResultsDisplay from './components/ResultsDisplay';
import CalculationsTable from './components/CalculationsTable';
import { calculateCurrentMetrics, calculateProjectedMetrics } from './utils/calculator';
import { ClientMetrics, ProjectDetails, CalculatedMetrics, ProjectedMetrics } from './types';
import { getStateFromUrl, setStateInUrl } from './utils/urlState';

const theme = createTheme({
  typography: {
    fontFamily: '"IBM Plex Sans", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 600,
      color: '#0063db',
      '@media (min-width:0px)': {
        fontSize: '1.75rem',
      },
      '@media (min-width:600px)': {
        fontSize: '2rem',
      },
      '@media (min-width:900px)': {
        fontSize: '2.125rem',
      }
    },
    h6: {
      fontWeight: 600
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#0063db',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (min-width:0px)': {
            paddingLeft: '16px',
            paddingRight: '16px'
          },
          '@media (min-width:600px)': {
            paddingLeft: '24px',
            paddingRight: '24px'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"IBM Plex Sans", "Helvetica", "Arial", sans-serif',
          fontWeight: 500,
          whiteSpace: 'nowrap'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"IBM Plex Sans", "Helvetica", "Arial", sans-serif',
        }
      }
    }
  }
});

const initialClientMetrics: ClientMetrics = {
  yearlyAdSpend: 2000000, // $2M per year
  currentRevenue: 8000000,
  annualTransactions: 100000,
  profitMargin: 0.25,
};

const initialProjectDetails: ProjectDetails = {
  projectBudget: 50000,
  projectTimeline: 12,
  improvementStartMonth: 4,
  improvementEndMonth: 8,
  initialRoasImprovement: 0.01,  // 1%
  finalRoasImprovement: 0.10,    // 10%
  isImprovementGrowing: true,
  certainty: 5
};

function App() {
  const [clientMetrics, setClientMetrics] = useState<ClientMetrics>(initialClientMetrics);
  const [projectDetails, setProjectDetails] = useState<ProjectDetails>(initialProjectDetails);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const urlState = getStateFromUrl();
    if (urlState) {
      setClientMetrics(urlState.clientMetrics);
      setProjectDetails(urlState.projectDetails);
    }
    
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }, []);

  const handleClientMetricsChange = (updates: Partial<ClientMetrics>) => {
    setClientMetrics(current => {
      const updated = { ...current, ...updates };
      updateUrl(updated, projectDetails);
      return updated;
    });
  };

  const handleProjectDetailsChange = (updates: Partial<ProjectDetails>) => {
    setProjectDetails(current => {
      const updated = { ...current, ...updates };
      updateUrl(clientMetrics, updated);
      return updated;
    });
  };

  const updateUrl = (metrics: ClientMetrics, details: ProjectDetails) => {
    setStateInUrl({ clientMetrics: metrics, projectDetails: details });
  };

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Failed to copy URL:', err);
    }
  };

  const calculatedMetrics = calculateCurrentMetrics(clientMetrics);
  const projectedMetrics = calculateProjectedMetrics(calculatedMetrics, projectDetails);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl" sx={{ py: { xs: 2, sm: 3, md: 4 } }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between', 
          alignItems: { xs: 'flex-start', sm: 'center' }, 
          gap: { xs: 2, sm: 0 },
          mb: { xs: 3, sm: 4 } 
        }}>
          <Typography variant="h4" component="h1">
            Data to Value: ROI Calculator
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ShareIcon />}
            onClick={handleShare}
            sx={{ alignSelf: { xs: 'stretch', sm: 'auto' } }}
          >
            Share Calculator
          </Button>
        </Box>
        
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, sm: 3 }}>
            <Grid item xs={12} lg={4}>
              <InputForm
                clientMetrics={clientMetrics}
                projectDetails={projectDetails}
                onClientMetricsChange={handleClientMetricsChange}
                onProjectDetailsChange={handleProjectDetailsChange}
              />
            </Grid>

            <Grid item xs={12} lg={8}>
              <ResultsDisplay
                currentMetrics={calculatedMetrics}
                projectDetails={projectDetails}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: { xs: 2, sm: 3, md: 4 } }}>
          <CalculationsTable
            clientMetrics={clientMetrics}
            calculatedMetrics={calculatedMetrics}
            projectDetails={projectDetails}
            projectedMetrics={projectedMetrics}
          />
        </Box>
      </Container>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Calculator URL copied to clipboard!"
      />
    </ThemeProvider>
  );
}

export default App;
