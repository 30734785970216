import { ClientMetrics, ProjectDetails } from '../types';

export interface AppState {
    clientMetrics: ClientMetrics;
    projectDetails: ProjectDetails;
}

export function encodeState(state: AppState): string {
    const encoded = btoa(JSON.stringify(state));
    return encoded;
}

export function decodeState(encoded: string): AppState | null {
    try {
        const decoded = JSON.parse(atob(encoded));
        return {
            clientMetrics: decoded.clientMetrics,
            projectDetails: decoded.projectDetails
        };
    } catch (e) {
        console.error('Failed to decode state:', e);
        return null;
    }
}

export function getStateFromUrl(): AppState | null {
    const params = new URLSearchParams(window.location.search);
    const state = params.get('state');
    if (!state) return null;
    return decodeState(state);
}

export function setStateInUrl(state: AppState) {
    const encoded = encodeState(state);
    const newUrl = `${window.location.pathname}?state=${encoded}`;
    window.history.pushState({}, '', newUrl);
}
