import React, { useState } from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Collapse,
    IconButton,
    styled,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ClientMetrics, CalculatedMetrics, ProjectDetails, ProjectedMetrics } from '../types';
import { formatNumber, formatCurrency } from '../utils/formatting';

const StyledTableCell = styled(TableCell)`
  font-weight: bold;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.breakpoints.down('sm') ? '8px' : '16px'};
  &:first-of-type {
    padding-left: ${({ theme }) => theme.breakpoints.down('sm') ? '16px' : '24px'};
  }
`;

const SubTableCell = styled(TableCell)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  padding: ${({ theme }) => theme.breakpoints.down('sm') ? '8px' : '16px'};
`;

const CodeBlock = styled('code')`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  padding: 2px 4px;
  border-radius: 4px;
  font-family: monospace;
  font-size: ${({ theme }) => theme.breakpoints.down('sm') ? '0.75em' : 
    theme.breakpoints.down('md') ? '0.85em' : '0.9em'};
  display: inline-block;
  max-width: 100%;
  overflow: auto;
`;

const FormulaBlock = styled('pre')`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  padding: ${({ theme }) => theme.breakpoints.down('sm') ? '6px' : '8px'};
  border-radius: 4px;
  font-family: monospace;
  font-size: ${({ theme }) => theme.breakpoints.down('sm') ? '0.75em' : 
    theme.breakpoints.down('md') ? '0.85em' : '0.9em'};
  margin: 4px 0;
  white-space: pre-wrap;
  overflow: auto;
  max-width: 100%;
  
  & .metric {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: bold;
  }
  
  & .separator {
    border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
    margin: 8px 0;
    width: 100%;
    display: block;
  }
  
  & .label {
    color: ${({ theme }) => theme.palette.grey[600]};
    font-style: italic;
  }
`;

interface CalculationsTableProps {
    clientMetrics: ClientMetrics;
    calculatedMetrics: CalculatedMetrics;
    projectDetails: ProjectDetails;
    projectedMetrics: ProjectedMetrics;
}

interface MetricDefinition {
    name: string;
    value: (m: any, p?: any, pm?: any) => JSX.Element;
    formula?: JSX.Element;
    explanation: string;
}

interface SectionDefinition {
    section: string;
    metrics: MetricDefinition[];
}

const calculations: SectionDefinition[] = [
    {
        section: 'Input Metrics',
        metrics: [
            {
                name: 'Yearly Ad Spend',
                value: (m: ClientMetrics) => <CodeBlock>{formatCurrency(m?.yearlyAdSpend || 0)}</CodeBlock>,
                explanation: 'Total annual advertising budget'
            },
            {
                name: 'Current Revenue',
                value: (m: ClientMetrics) => <CodeBlock>{formatCurrency(m?.currentRevenue || 0)}</CodeBlock>,
                explanation: 'Current annual revenue'
            },
            {
                name: 'Annual Transactions',
                value: (m: ClientMetrics) => <CodeBlock>{formatNumber(m?.annualTransactions || 0)}</CodeBlock>,
                explanation: 'Total number of transactions per year'
            },
            {
                name: 'Profit Margin',
                value: (m: ClientMetrics) => <CodeBlock>{((m?.profitMargin || 0) * 100).toFixed(1)}%</CodeBlock>,
                explanation: 'Percentage of revenue that becomes profit'
            }
        ]
    },
    {
        section: 'Current Performance Metrics',
        metrics: [
            {
                name: 'Monthly Transactions',
                value: (m: CalculatedMetrics) => <CodeBlock>{formatNumber(m?.monthlyTransactions || 0)}</CodeBlock>,
                formula: <FormulaBlock><span className="metric">Annual Transactions</span> / 12</FormulaBlock>,
                explanation: 'Average number of transactions per month'
            },
            {
                name: 'Current CPA (Cost Per Acquisition)',
                value: (m: CalculatedMetrics) => <CodeBlock>{formatCurrency(m?.currentCPA || 0)}</CodeBlock>,
                formula: <FormulaBlock><span className="metric">Yearly Ad Spend</span> / <span className="metric">Annual Transactions</span></FormulaBlock>,
                explanation: 'Average cost to acquire one customer'
            },
            {
                name: 'Profit Per Transaction',
                value: (m: CalculatedMetrics) => <CodeBlock>{formatCurrency(m?.profitPerTransaction || 0)}</CodeBlock>,
                formula: <FormulaBlock>(<span className="metric">Current Revenue</span> / <span className="metric">Annual Transactions</span>) * <span className="metric">Profit Margin</span></FormulaBlock>,
                explanation: 'Average profit made from each transaction'
            },
            {
                name: 'Current Annual Profit',
                value: (m: CalculatedMetrics) => <CodeBlock>{formatCurrency(m?.currentAnnualProfit || 0)}</CodeBlock>,
                formula: <FormulaBlock><span className="metric">Current Revenue</span> * <span className="metric">Profit Margin</span></FormulaBlock>,
                explanation: 'Total annual profit before improvements'
            },
            {
                name: 'Current ROI',
                value: (m: CalculatedMetrics) => <CodeBlock>{((m?.currentROI || 0) * 100).toFixed(1)}%</CodeBlock>,
                formula: <FormulaBlock>((<span className="metric">Current Annual Profit</span> - <span className="metric">Yearly Ad Spend</span>) / <span className="metric">Yearly Ad Spend</span>) * 100</FormulaBlock>,
                explanation: 'Return on investment from current advertising spend'
            }
        ]
    },
    {
        section: 'Project Details & ROAS Improvement Calculations',
        metrics: [
            {
                name: 'Gradual Improvement',
                value: (m: any, p: ProjectDetails) => <CodeBlock>{p.isImprovementGrowing ? 'Yes' : 'No'}</CodeBlock>,
                formula: <FormulaBlock>Monthly Multiplier = 1 + (<span className="metric">ROAS Improvement</span> * <span className="metric">current_month</span> / <span className="metric">total_months</span>)</FormulaBlock>,
                explanation: 'When enabled, ROAS improvement increases linearly from 0% to target % over the project timeline. When disabled, full improvement is applied immediately.'
            },
            {
                name: 'Project Budget',
                value: (m: any, p: ProjectDetails) => <CodeBlock>{formatCurrency(p?.projectBudget || 0)}</CodeBlock>,
                explanation: 'Total budget allocated for the improvement project'
            },
            {
                name: 'Project Timeline',
                value: (m: any, p: ProjectDetails) => <CodeBlock>{p?.projectTimeline || 0} months</CodeBlock>,
                explanation: 'Expected duration of the project'
            },
            {
                name: 'Final ROAS Improvement',
                value: (m: any, p: ProjectDetails) => <CodeBlock>{((p?.finalRoasImprovement || 0) * 100).toFixed(1)}%</CodeBlock>,
                formula: <FormulaBlock>User input as percentage</FormulaBlock>,
                explanation: 'Expected improvement in Return on Ad Spend after project completion'
            },
            {
                name: 'Certainty Level',
                value: (m: any, p: ProjectDetails) => <CodeBlock>{p.certainty}/10</CodeBlock>,
                formula: <FormulaBlock>Range % = 50% - (45% * (<span className="metric">certainty</span> - 1) / 9)

Example: <span className="metric">certainty</span> = 5 → Range = 27.5%</FormulaBlock>,
                explanation: 'Higher certainty (1-10) reduces the range between upper and lower estimates. 1 = 50% range, 10 = 5% range.'
            },
            {
                name: 'Range Percentage',
                value: (m: any, p: any, pm: ProjectedMetrics) => <CodeBlock>±{((pm?.rangePercentage || 0.2) * 100).toFixed(1)}%</CodeBlock>,
                formula: <FormulaBlock>Calculated from <span className="metric">Certainty Level</span></FormulaBlock>,
                explanation: 'Percentage range for upper and lower bound estimates'
            },
            {
                name: 'ROAS Multiplier (Base)',
                value: (m: any, p: ProjectDetails) => <CodeBlock>{(1 + (p?.finalRoasImprovement || 0)).toFixed(2)}x</CodeBlock>,
                formula: <FormulaBlock>1 + <span className="metric">ROAS Improvement</span></FormulaBlock>,
                explanation: 'Multiplier used to calculate improved metrics'
            },
            {
                name: 'ROAS Multiplier (Lower)',
                value: (m: any, p: ProjectDetails, pm: ProjectedMetrics) => 
                    <CodeBlock>{(1 + ((p?.finalRoasImprovement || 0) * (1 - (pm?.rangePercentage || 0.2)))).toFixed(2)}x</CodeBlock>,
                formula: <FormulaBlock>1 + (<span className="metric">ROAS Improvement</span> * (1 - <span className="metric">Range Percentage</span>))</FormulaBlock>,
                explanation: 'Conservative estimate multiplier'
            },
            {
                name: 'ROAS Multiplier (Upper)',
                value: (m: any, p: ProjectDetails, pm: ProjectedMetrics) => 
                    <CodeBlock>{(1 + ((p?.finalRoasImprovement || 0) * (1 + (pm?.rangePercentage || 0.2)))).toFixed(2)}x</CodeBlock>,
                formula: <FormulaBlock>1 + (<span className="metric">ROAS Improvement</span> * (1 + <span className="metric">Range Percentage</span>))</FormulaBlock>,
                explanation: 'Optimistic estimate multiplier'
            }
        ]
    },
    {
        section: 'Monthly Performance Projections',
        metrics: [
            {
                name: 'Monthly CPA',
                value: (m: CalculatedMetrics, p: any, pm: ProjectedMetrics) => 
                    <CodeBlock>{formatCurrency(pm?.projectedCPA?.[pm.projectedCPA.length - 1] || 0)} ({formatCurrency(pm?.projectedCPALow?.[pm.projectedCPALow.length - 1] || 0)} - {formatCurrency(pm?.projectedCPAHigh?.[pm.projectedCPAHigh.length - 1] || 0)})</CodeBlock>,
                formula: <FormulaBlock><span className="label">Base:</span>
<span className="metric">Current CPA</span> / <span className="metric">ROAS Multiplier</span>

<span className="separator" />
<span className="label">Range Estimates:</span>
Lower = <span className="metric">Current CPA</span> / <span className="metric">ROAS Multiplier (Upper)</span>
Upper = <span className="metric">Current CPA</span> / <span className="metric">ROAS Multiplier (Lower)</span></FormulaBlock>,
                explanation: 'Projected cost per acquisition showing base case and range. Lower CPA is better.'
            },
            {
                name: 'Monthly Transactions',
                value: (m: CalculatedMetrics, p: any, pm: ProjectedMetrics) => 
                    <CodeBlock>{formatNumber(pm?.projectedTransactions?.[pm.projectedTransactions.length - 1] || 0)} ({formatNumber(pm?.projectedTransactionsLow?.[pm.projectedTransactionsLow.length - 1] || 0)} - {formatNumber(pm?.projectedTransactionsHigh?.[pm.projectedTransactionsHigh.length - 1] || 0)})</CodeBlock>,
                formula: <FormulaBlock><span className="label">Base:</span>
(<span className="metric">Yearly Ad Spend</span> / 12) / <span className="metric">Monthly CPA</span>

<span className="separator" />
<span className="label">Range Estimates:</span>
Lower = (<span className="metric">Yearly Ad Spend</span> / 12) / <span className="metric">Monthly CPA (Upper)</span>
Upper = (<span className="metric">Yearly Ad Spend</span> / 12) / <span className="metric">Monthly CPA (Lower)</span></FormulaBlock>,
                explanation: 'Projected monthly transactions showing base case and range'
            }
        ]
    },
    {
        section: 'Annual Performance Projections',
        metrics: [
            {
                name: 'Additional Annual Transactions',
                value: (m: any, p: any, pm: ProjectedMetrics) => 
                    <CodeBlock>{formatNumber(pm?.additionalAnnualTransactions || 0)} ({formatNumber(pm?.additionalAnnualTransactionsLow || 0)} - {formatNumber(pm?.additionalAnnualTransactionsHigh || 0)})</CodeBlock>,
                formula: <FormulaBlock><span className="label">Base:</span>
(<span className="metric">Final Monthly Transactions</span> - <span className="metric">Current Monthly Transactions</span>) * 12

<span className="separator" />
<span className="label">Range Estimates:</span>
Same formula using respective monthly transactions:
Lower = Using lower monthly transactions
Upper = Using upper monthly transactions</FormulaBlock>,
                explanation: 'Projected increase in yearly transactions after improvements'
            },
            {
                name: 'Additional Annual Profit',
                value: (m: any, p: any, pm: ProjectedMetrics) => 
                    <CodeBlock>{formatCurrency(pm?.additionalAnnualProfit || 0)} ({formatCurrency(pm?.additionalAnnualProfitLow || 0)} - {formatCurrency(pm?.additionalAnnualProfitHigh || 0)})</CodeBlock>,
                formula: <FormulaBlock><span className="label">Base:</span>
<span className="metric">Additional Annual Transactions</span> * <span className="metric">Profit per Transaction</span>

<span className="separator" />
<span className="label">Range Estimates:</span>
Same formula using respective transaction numbers:
Lower = Using lower transaction numbers
Upper = Using upper transaction numbers</FormulaBlock>,
                explanation: 'Projected increase in yearly profit after improvements'
            },
            {
                name: 'Projected Annual Profit',
                value: (m: any, p: any, pm: ProjectedMetrics) => 
                    <CodeBlock>{formatCurrency(pm?.projectedAnnualProfit || 0)} ({formatCurrency(pm?.projectedAnnualProfitLow || 0)} - {formatCurrency(pm?.projectedAnnualProfitHigh || 0)})</CodeBlock>,
                formula: <FormulaBlock><span className="label">Base:</span>
<span className="metric">Current Annual Profit</span> + <span className="metric">Additional Annual Profit</span>

<span className="separator" />
<span className="label">Range Estimates:</span>
Same formula using respective additional profits:
Lower = Using lower additional profits
Upper = Using upper additional profits</FormulaBlock>,
                explanation: 'Total expected yearly profit after improvements'
            }
        ]
    },
    {
        section: 'ROI and Investment Metrics',
        metrics: [
            {
                name: 'Projected ROI',
                value: (m: any, p: any, pm: ProjectedMetrics) => 
                    <CodeBlock>{(pm?.projectedROI || 0).toFixed(1)}% ({(pm?.projectedROILow || 0).toFixed(1)}% - {(pm?.projectedROIHigh || 0).toFixed(1)}%)</CodeBlock>,
                formula: <FormulaBlock><span className="label">Base:</span>
((<span className="metric">Projected Annual Profit</span> - <span className="metric">Current Annual Profit</span>) / <span className="metric">Project Budget</span>) * 100

<span className="separator" />
<span className="label">Range Estimates:</span>
Same formula using respective profit projections:
Lower = Using lower profit projections
Upper = Using upper profit projections</FormulaBlock>,
                explanation: 'Expected return on investment from the improvement project'
            },
            {
                name: 'Break Even Month',
                value: (m: any, p: any, pm: ProjectedMetrics) => 
                    <CodeBlock>Month {pm?.breakEvenMonth || 0} ({pm?.breakEvenMonthLow || 0} - {pm?.breakEvenMonthHigh || 0})</CodeBlock>,
                formula: <FormulaBlock><span className="label">Base:</span>
First month where Cumulative Additional Profit ≥ <span className="metric">Project Budget</span>

<span className="separator" />
<span className="label">Range Estimates:</span>
Same condition using respective profit accumulation:
Lower = Using lower profit accumulation
Upper = Using upper profit accumulation</FormulaBlock>,
                explanation: 'Month when cumulative additional profit exceeds project cost'
            },
            {
                name: 'Annualized IRR',
                value: (m: any, p: any, pm: ProjectedMetrics) => 
                    <CodeBlock>{((pm?.annualizedIRR || 0)).toFixed(1)}% ({(pm?.annualizedIRRLow || 0).toFixed(1)}% - {(pm?.annualizedIRRHigh || 0).toFixed(1)}%)</CodeBlock>,
                formula: <FormulaBlock><span className="label">Base:</span>
IRR of monthly cash flows * 12 * 100
Cash flows = [-<span className="metric">Project Budget</span>, <span className="metric">Monthly Additional Profits</span>...]

<span className="separator" />
<span className="label">Range Estimates:</span>
Same formula using respective monthly profits:
Lower = Using lower monthly profits
Upper = Using upper monthly profits</FormulaBlock>,
                explanation: 'Internal Rate of Return, annualized for comparison with other investments. Calculated using monthly cash flows starting with initial investment (negative) followed by monthly additional profits.'
            }
        ]
    }
];

export const CalculationsTable: React.FC<CalculationsTableProps> = ({
    clientMetrics,
    calculatedMetrics,
    projectDetails,
    projectedMetrics,
}) => {
    const [open, setOpen] = useState(false);

    return (
        <Box sx={{ width: '100%', mt: 4 }}>
            <Paper 
                sx={{ 
                    p: 2,
                    cursor: 'pointer',
                    '&:hover': { bgcolor: 'action.hover' }
                }}
                onClick={() => setOpen(!open)}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h6">
                        Detailed Calculations Reference
                    </Typography>
                    <IconButton>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </Box>
            </Paper>
            
            <Collapse in={open}>
                <TableContainer component={Paper} sx={{ 
                    mt: 2,
                    overflow: 'auto',
                    '& .MuiTable-root': {
                        minWidth: {
                            xs: '800px',
                            md: '1000px'
                        }
                    },
                    '& .MuiTableCell-root': {
                        fontSize: {
                            xs: '0.875rem',
                            sm: '1rem'
                        }
                    }
                }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Metric</StyledTableCell>
                                <StyledTableCell>Value</StyledTableCell>
                                <StyledTableCell>Formula</StyledTableCell>
                                <StyledTableCell>Explanation</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {calculations.map((section, index) => (
                                <React.Fragment key={section.section}>
                                    <TableRow>
                                        <TableCell
                                            colSpan={4}
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'common.white',
                                                fontWeight: 'bold',
                                                fontSize: {
                                                    xs: '1rem',
                                                    sm: '1.1rem'
                                                },
                                                padding: {
                                                    xs: '12px 16px',
                                                    sm: '16px 24px'
                                                }
                                            }}
                                        >
                                            {section.section}
                                        </TableCell>
                                    </TableRow>
                                    {section.metrics.map((metric) => (
                                        <TableRow key={metric.name}>
                                            <TableCell>{metric.name}</TableCell>
                                            <TableCell>
                                                {section.section === 'Current Performance Metrics' 
                                                    ? metric.value(calculatedMetrics, projectDetails, projectedMetrics)
                                                    : metric.value(clientMetrics, projectDetails, projectedMetrics)}
                                            </TableCell>
                                            <TableCell>{metric.formula || '—'}</TableCell>
                                            <TableCell>{metric.explanation}</TableCell>
                                        </TableRow>
                                    ))}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Collapse>
        </Box>
    );
};

export default CalculationsTable;
