import React, { useRef, useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Line, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartData,
    ChartOptions,
    TooltipItem,
    ScaleOptions
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CalculatedMetrics, ProjectedMetrics, ProjectDetails, ClientMetrics, LongTermProjection } from '../types';
import { calculateCurrentMetrics, calculateProjectedMetrics, calculateLongTermProjection } from '../utils/calculator';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
);

interface Props {
    currentMetrics: CalculatedMetrics;
    projectDetails: ProjectDetails;
}

const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};

const formatNumber = (value: number) => {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(value);
};

const formatPercentage = (value: number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    }).format(value / 100);
};

const formatRange = (low: number, high: number, formatter: (n: number) => string) => {
    return (
        <Box component="span">
            <Typography component="span">
                {formatter(low)} - {formatter(high)}
            </Typography>
        </Box>
    );
};

const formatRangeWithPercentage = (low: number, high: number, formatter: (n: number) => string, currentValue: number, invertColors: boolean = false) => {
    const lowStr = formatter(low);
    const highStr = formatter(high);
    
    const lowPercentChange = ((low - currentValue) / Math.abs(currentValue)) * 100;
    const highPercentChange = ((high - currentValue) / Math.abs(currentValue)) * 100;
    
    const lowPercentColor = invertColors 
        ? lowPercentChange > 0 ? 'error.main' : 'success.main'
        : lowPercentChange < 0 ? 'error.main' : 'success.main';
    const highPercentColor = invertColors
        ? highPercentChange > 0 ? 'error.main' : 'success.main'
        : highPercentChange < 0 ? 'error.main' : 'success.main';
    
    return (
        <Box component="span">
            <Typography component="span">
                {lowStr}
            </Typography>
            <Typography component="span" color={lowPercentColor} sx={{ ml: 1 }}>
                ({lowPercentChange.toFixed(1)}%)
            </Typography>
            {' - '}
            <Typography component="span">
                {highStr}
            </Typography>
            <Typography component="span" color={highPercentColor} sx={{ ml: 1 }}>
                ({highPercentChange.toFixed(1)}%)
            </Typography>
        </Box>
    );
};

const formatChangeValue = (value: number, formatter: (n: number) => string) => {
    const color = value < 0 ? 'error.main' : 'success.main';
    return (
        <Typography component="span" color={color}>
            {formatter(value)}
        </Typography>
    );
};

const ResultsDisplay: React.FC<Props> = ({ currentMetrics, projectDetails }) => {
    const chartRef = useRef<any>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [chartWidth, setChartWidth] = useState<number>(0);

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setChartWidth(containerRef.current.offsetWidth);
            }
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const projectedMetrics = calculateProjectedMetrics(currentMetrics, projectDetails);

    const findBreakEvenMonth = (cumulativeValues: number[], target: number): number => {
        const breakEvenIndex = cumulativeValues.findIndex(value => value >= target);
        return breakEvenIndex === -1 ? -1 : breakEvenIndex + 1;
    };

    // Find when the returns cross the total investment line
    const optimisticBreakEven = findBreakEvenMonth(
        projectedMetrics.monthlyData.map(d => d.cumulativeProfitHigh + d.cumulativeCost),
        projectDetails.projectBudget
    );
    const pessimisticBreakEven = findBreakEvenMonth(
        projectedMetrics.monthlyData.map(d => d.cumulativeProfitLow + d.cumulativeCost),
        projectDetails.projectBudget
    );

    const latestBreakEven = Math.max(
        pessimisticBreakEven === -1 ? 0 : pessimisticBreakEven,
        6  // Minimum 6 months to show trend
    );
    
    const displayMonths = latestBreakEven === 0 ? 
        Math.max(12, projectedMetrics.monthlyData.length) : // Show at least 12 months if no break-even
        Math.min(latestBreakEven + 3, projectedMetrics.monthlyData.length);

    const displayData = projectedMetrics.monthlyData.slice(0, displayMonths);

    const breakEvenText = optimisticBreakEven === -1 && pessimisticBreakEven === -1 
        ? "Break-even not reached within projection period"
        : optimisticBreakEven === pessimisticBreakEven
        ? `Break-even expected in month ${optimisticBreakEven}`
        : `Break-even expected between month ${optimisticBreakEven} and ${
            pessimisticBreakEven === -1 
            ? "beyond projection period" 
            : `month ${pessimisticBreakEven}`
          }`;

    const months = Array.from(
        { length: projectedMetrics.projectedTransactions.length }, 
        (_, i) => `Month ${i + 1}`
    );

    const transactionsChartData: ChartData<'line'> = {
        labels: months,
        datasets: [
            {
                label: 'Monthly Transactions',
                data: projectedMetrics.projectedTransactions,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            }
        ]
    };

    const cpaChartData: ChartData<'line'> = {
        labels: months,
        datasets: [
            {
                label: 'Cost Per Acquisition ($)',
                data: projectedMetrics.projectedCPA,
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1
            }
        ]
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom' as const,
                labels: {
                    padding: 20,
                    font: {
                        size: window.innerWidth < 600 ? 10 : 12
                    }
                }
            },
            title: {
                display: true,
                text: 'Break-Even Analysis',
                font: {
                    size: window.innerWidth < 600 ? 14 : 16,
                    weight: 'bold'
                }
            },
            tooltip: {
                callbacks: {
                    label: function(this: any, context: TooltipItem<'line'>) {
                        return `${context.dataset.label}: ${formatCurrency(Number(context.parsed.y))}`;
                    }
                }
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Month',
                    font: {
                        size: window.innerWidth < 600 ? 10 : 12
                    }
                },
                ticks: {
                    font: {
                        size: window.innerWidth < 600 ? 10 : 12
                    }
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Cumulative Profit ($)',
                    font: {
                        size: window.innerWidth < 600 ? 10 : 12
                    }
                },
                ticks: {
                    callback: function(this: any, value: string | number) {
                        return formatCurrency(Number(value));
                    },
                    font: {
                        size: window.innerWidth < 600 ? 10 : 12
                    }
                }
            }
        }
    };

    const breakEvenChartData: ChartData<'line'> = {
        labels: displayData.map(d => `Month ${d.month}`),
        datasets: [
            {
                label: 'Project Cost',
                data: displayData.map(d => d.cumulativeCost),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false,
                pointRadius: 0
            },
            {
                label: 'Expected Return',
                data: displayData.map(d => d.cumulativeReturn),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                fill: false,
                pointRadius: 0
            },
            {
                label: 'Upper Return Estimate',
                data: displayData.map(d => d.cumulativeProfitHigh + d.cumulativeCost),
                borderColor: 'rgb(128, 128, 128)',
                borderDash: [5, 5],
                backgroundColor: 'transparent',
                fill: false,
                pointRadius: 0
            },
            {
                label: 'Lower Return Estimate',
                data: displayData.map(d => d.cumulativeProfitLow + d.cumulativeCost),
                borderColor: 'rgb(128, 128, 128)',
                borderDash: [5, 5],
                backgroundColor: 'transparent',
                fill: false,
                pointRadius: 0
            },
            {
                label: 'Profit/Loss Area',
                data: displayData.map(d => d.cumulativeReturn),
                borderColor: 'transparent',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: {
                    target: 0,
                    above: 'rgba(75, 192, 192, 0.2)',
                    below: 'rgba(255, 99, 132, 0.2)'
                },
                pointRadius: 0
            }
        ]
    };

    const breakEvenChartOptions: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: 'Break-even Analysis (Additional Return vs. Project Cost)'
            },
            legend: {
                position: 'top' as const,
                labels: {
                    filter: (item) => item.text !== 'Profit/Loss Area'
                }
            },
            tooltip: {
                callbacks: {
                    label: (context: TooltipItem<'line'>) => {
                        const value = context.raw as number;
                        return `${context.dataset.label}: ${formatCurrency(Number(value))}`;
                    }
                }
            },
            annotation: {
                annotations: {
                    projectInvestmentLine: {
                        type: 'line',
                        yMin: projectDetails.projectBudget,
                        yMax: projectDetails.projectBudget,
                        borderColor: 'rgb(255, 0, 0)',
                        borderWidth: 2,
                        borderDash: [6, 6],
                        label: {
                            display: true,
                            content: 'Total Investment',
                            position: 'start'
                        }
                    }
                }
            }
        },
        interaction: {
            intersect: false,
            mode: 'index'
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function(this: any, value: string | number) {
                        return formatCurrency(Number(value));
                    }
                },
                grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)'
                }
            } as ScaleOptions<'linear'>,
            x: {
                grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)'
                }
            }
        }
    };

    const ProjectedMetricsTab: React.FC<{ currentMetrics: CalculatedMetrics; projectedMetrics: ProjectedMetrics }> = ({
        currentMetrics,
        projectedMetrics
    }) => {
        return (
            <TableContainer component={Paper}>
                <Table size={window.innerWidth < 600 ? "small" : "medium"}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ 
                                fontWeight: 'bold',
                                fontSize: {
                                    xs: '0.875rem',
                                    sm: '1rem'
                                }
                            }}>Metric</TableCell>
                            <TableCell sx={{ 
                                fontWeight: 'bold',
                                fontSize: {
                                    xs: '0.875rem',
                                    sm: '1rem'
                                }
                            }}>Current</TableCell>
                            <TableCell sx={{ 
                                fontWeight: 'bold',
                                fontSize: {
                                    xs: '0.875rem',
                                    sm: '1rem'
                                }
                            }}>Projected</TableCell>
                            <TableCell sx={{ 
                                fontWeight: 'bold',
                                fontSize: {
                                    xs: '0.875rem',
                                    sm: '1rem'
                                }
                            }}>Change</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Annual Transactions</TableCell>
                            <TableCell align="right">{formatNumber(currentMetrics.monthlyTransactions * 12)}</TableCell>
                            <TableCell align="right">
                                {formatRangeWithPercentage(
                                    (currentMetrics.monthlyTransactions * 12) + projectedMetrics.additionalAnnualTransactionsLow,
                                    (currentMetrics.monthlyTransactions * 12) + projectedMetrics.additionalAnnualTransactionsHigh,
                                    formatNumber,
                                    currentMetrics.monthlyTransactions * 12
                                )}
                            </TableCell>
                            <TableCell align="right">
                                {formatRange(
                                    projectedMetrics.additionalAnnualTransactionsLow,
                                    projectedMetrics.additionalAnnualTransactionsHigh,
                                    formatNumber
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Cost per Acquisition (CPA)</TableCell>
                            <TableCell align="right">{formatCurrency(currentMetrics.currentCPA)}</TableCell>
                            <TableCell align="right">
                                {formatRangeWithPercentage(
                                    projectedMetrics.projectedCPALow[projectedMetrics.projectedCPALow.length - 1],
                                    projectedMetrics.projectedCPAHigh[projectedMetrics.projectedCPAHigh.length - 1],
                                    formatCurrency,
                                    currentMetrics.currentCPA,
                                    true
                                )}
                            </TableCell>
                            <TableCell align="right">
                                {formatRange(
                                    projectedMetrics.projectedCPALow[projectedMetrics.projectedCPALow.length - 1] - currentMetrics.currentCPA,
                                    projectedMetrics.projectedCPAHigh[projectedMetrics.projectedCPAHigh.length - 1] - currentMetrics.currentCPA,
                                    formatCurrency
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Annual Profit</TableCell>
                            <TableCell align="right">{formatCurrency(currentMetrics.currentAnnualProfit)}</TableCell>
                            <TableCell align="right">
                                {formatRangeWithPercentage(
                                    projectedMetrics.projectedAnnualProfitLow,
                                    projectedMetrics.projectedAnnualProfitHigh,
                                    formatCurrency,
                                    currentMetrics.currentAnnualProfit
                                )}
                            </TableCell>
                            <TableCell align="right">
                                {formatRange(
                                    projectedMetrics.additionalAnnualProfitLow,
                                    projectedMetrics.additionalAnnualProfitHigh,
                                    formatCurrency
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>First-Year Project ROI</TableCell>
                            <TableCell align="right">-</TableCell>
                            <TableCell align="right">
                                {formatRange(
                                    projectedMetrics.projectedROILow,
                                    projectedMetrics.projectedROIHigh,
                                    formatPercentage
                                )}
                            </TableCell>
                            <TableCell align="right">-</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Break-even Period</TableCell>
                            <TableCell align="right">-</TableCell>
                            <TableCell align="right">
                                <Typography component="span">
                                    {breakEvenText}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">-</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Annualized IRR</TableCell>
                            <TableCell align="right">-</TableCell>
                            <TableCell align="right">
                                {formatRange(
                                    projectedMetrics.annualizedIRRLow,
                                    projectedMetrics.annualizedIRRHigh,
                                    formatPercentage
                                )}
                            </TableCell>
                            <TableCell align="right">-</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const LongTermComparisonChart: React.FC<{ projection: LongTermProjection }> = ({ projection }) => {
        const chartData: ChartData<'bar'> = {
            labels: projection.yearLabels,
            datasets: [
                {
                    label: 'Revenue with Investment & Reinvestment',
                    data: projection.yearlyRevenueWithInvestment,
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    borderColor: 'rgb(75, 192, 192)',
                    borderWidth: 1
                },
                {
                    label: 'Revenue without Investment',
                    data: projection.yearlyRevenueWithoutInvestment,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    borderColor: 'rgb(255, 99, 132)',
                    borderWidth: 1
                }
            ]
        };

        const chartOptions: ChartOptions<'bar'> = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: true,
                    text: '5-Year Revenue Growth Projection'
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const value = context.raw as number;
                            return `${context.dataset.label}: ${formatCurrency(Number(value))}`;
                        }
                    }
                }
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Year'
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'Annual Revenue'
                    },
                    ticks: {
                        callback: function(this: any, value: string | number) {
                            return formatCurrency(Number(value));
                        }
                    }
                }
            }
        };

        return (
            <Box sx={{ width: '100%', height: chartWidth * 0.63 }}>
                <Bar data={chartData} options={chartOptions} />
            </Box>
        );
    };

    return (
        <Box>
            <Grid container spacing={{ xs: 2, sm: 3 }}>
                <Grid item xs={12}>
                    <Paper sx={{ p: { xs: 2, sm: 3 } }}>
                        <Typography variant="h6" gutterBottom>Break-Even Analysis</Typography>
                        <Box sx={{ 
                            height: { xs: '300px', sm: '400px' },
                            position: 'relative'
                        }}>
                            <Line data={breakEvenChartData} options={breakEvenChartOptions} />
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper sx={{ p: { xs: 2, sm: 3 } }}>
                        <Typography variant="h6" gutterBottom>Summary Metrics</Typography>
                        <ProjectedMetricsTab currentMetrics={currentMetrics} projectedMetrics={projectedMetrics} />
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper sx={{ p: { xs: 2, sm: 3 } }}>
                        <Typography variant="h6" gutterBottom>Long-Term Revenue Impact</Typography>
                        <Typography variant="body2" color="text.secondary">
                            Projected revenue growth over 5 years, comparing scenarios with and without investment. 
                            The investment scenario assumes all additional profits are reinvested into marketing at the improved CPA.
                        </Typography>
                        <LongTermComparisonChart projection={calculateLongTermProjection(currentMetrics, projectDetails)} />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ResultsDisplay;
