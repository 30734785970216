import React from 'react';
import {
    Box,
    Grid,
    Paper,
    Typography,
    TextField,
    Slider,
    Switch,
    FormControl,
    FormControlLabel,
    Tooltip,
    InputAdornment
} from '@mui/material';
import { ClientMetrics, ProjectDetails } from '../types';

interface InputFormProps {
    clientMetrics: ClientMetrics;
    projectDetails: ProjectDetails;
    onClientMetricsChange: (metrics: Partial<ClientMetrics>) => void;
    onProjectDetailsChange: (details: Partial<ProjectDetails>) => void;
}

const InputForm: React.FC<InputFormProps> = ({
    clientMetrics,
    projectDetails,
    onClientMetricsChange,
    onProjectDetailsChange
}) => {
    const formatNumber = (value: number): string => {
        return new Intl.NumberFormat('en-US').format(value);
    };

    const formatCurrency = (value: number): string => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    const parseNumber = (value: string): number => {
        // Remove all non-digit characters except decimal point
        const cleanValue = value.replace(/[^\d.]/g, '');
        return Number(cleanValue) || 0;
    };

    const handleClientMetricsChange = (field: keyof ClientMetrics) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseNumber(event.target.value);
        onClientMetricsChange({ [field]: value });
    };

    const handleProjectDetailsChange = (field: keyof ProjectDetails, value: any) => {
        onProjectDetailsChange({ [field]: value });
    };

    const handleCertaintyChange = (_event: Event, value: number | number[]) => {
        onProjectDetailsChange({ certainty: value as number });
    };

    const getCertaintyLabel = (value: number) => {
        const percentage = 50 - (45 * (value - 1) / 9);
        return `Certainty: ${value}/10 (±${percentage.toFixed(1)}%)`;
    };

    return (
        <Box component={Paper} p={3} mb={3}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Client Metrics
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Yearly Ad Spend ($)"
                        type="text"
                        value={formatNumber(clientMetrics.yearlyAdSpend)}
                        onChange={handleClientMetricsChange('yearlyAdSpend')}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Current Revenue (per year) ($)"
                        type="text"
                        value={formatNumber(clientMetrics.currentRevenue)}
                        onChange={handleClientMetricsChange('currentRevenue')}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Annual Transactions (per year)"
                        type="text"
                        value={formatNumber(clientMetrics.annualTransactions)}
                        onChange={handleClientMetricsChange('annualTransactions')}
                    />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Profit Margin (%)"
                        type="number"
                        value={clientMetrics.profitMargin * 100}
                        onChange={(e) => onClientMetricsChange({ profitMargin: Number(e.target.value) / 100 })}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ mt: 2, mb: 2, p: 2, bgcolor: 'grey.50', borderRadius: 1 }}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Live Calculations
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2" color="text.secondary">
                                    Average CPA
                                </Typography>
                                <Typography variant="body1">
                                    {formatCurrency(clientMetrics.yearlyAdSpend / clientMetrics.annualTransactions)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2" color="text.secondary">
                                    Average Profit per Order
                                </Typography>
                                <Typography variant="body1">
                                    {formatCurrency((clientMetrics.currentRevenue / clientMetrics.annualTransactions) * clientMetrics.profitMargin)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2" color="text.secondary">
                                    Winback Time
                                </Typography>
                                <Typography variant="body1">
                                    {formatNumber(clientMetrics.yearlyAdSpend / (clientMetrics.currentRevenue * clientMetrics.profitMargin))} months
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2" color="text.secondary">
                                    Return on Ad Spend (ROAS)
                                </Typography>
                                <Typography variant="body1">
                                    {(clientMetrics.currentRevenue / clientMetrics.yearlyAdSpend).toFixed(2)}x
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                        Project Details
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Project Budget ($)"
                        type="text"
                        value={formatNumber(projectDetails.projectBudget)}
                        onChange={(e) => handleProjectDetailsChange('projectBudget', parseNumber(e.target.value))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Project Timeline (months)"
                        type="number"
                        value={projectDetails.projectTimeline}
                        onChange={(e) => handleProjectDetailsChange('projectTimeline', Number(e.target.value))}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">months</InputAdornment>,
                        }}
                    />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Improvement Start Month"
                        type="number"
                        value={projectDetails.improvementStartMonth}
                        onChange={(e) => handleProjectDetailsChange('improvementStartMonth', Number(e.target.value))}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">month</InputAdornment>,
                        }}
                    />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Improvement End Month"
                        type="number"
                        value={projectDetails.improvementEndMonth}
                        onChange={(e) => handleProjectDetailsChange('improvementEndMonth', Number(e.target.value))}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">month</InputAdornment>,
                        }}
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <Box sx={{ mt: 2, mb: 2 }}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Improvement Settings
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Initial ROAS Improvement (%)"
                                    type="number"
                                    value={projectDetails.initialRoasImprovement * 100}
                                    onChange={(e) => handleProjectDetailsChange('initialRoasImprovement', Number(e.target.value) / 100)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Final ROAS Improvement (%)"
                                    type="number"
                                    value={projectDetails.finalRoasImprovement * 100}
                                    onChange={(e) => handleProjectDetailsChange('finalRoasImprovement', Number(e.target.value) / 100)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={projectDetails.isImprovementGrowing}
                                            onChange={(e) => handleProjectDetailsChange('isImprovementGrowing', e.target.checked)}
                                        />
                                    }
                                    label="Gradually increase improvement"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <Typography id="certainty-slider" gutterBottom>
                            Certainty Level
                        </Typography>
                        <Slider
                            value={projectDetails.certainty}
                            onChange={(_, value) => handleProjectDetailsChange('certainty', value)}
                            min={1}
                            max={10}
                            step={1}
                            marks={[
                                { value: 1, label: 'Low' },
                                { value: 5, label: 'Medium' },
                                { value: 10, label: 'High' }
                            ]}
                            aria-labelledby="certainty-slider"
                        />
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                            Indicates your confidence in the ROI projections. A lower certainty (1) creates a wider range 
                            of possible outcomes (±45% from projected values), while higher certainty (10) creates a 
                            narrower range (±5%). Currently set to {projectDetails.certainty}, creating a 
                            ±{(0.5 - (0.45 * (projectDetails.certainty - 1) / 9)) * 100}% range. For example, with a 10% 
                            ROI improvement and current certainty, outcomes could range from 
                            {(10 * (1 - (0.5 - (0.45 * (projectDetails.certainty - 1) / 9)))).toFixed(1)}% to 
                            {(10 * (1 + (0.5 - (0.45 * (projectDetails.certainty - 1) / 9)))).toFixed(1)}%.
                        </Typography>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};

export default InputForm;
