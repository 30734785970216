import { ClientMetrics, ProjectDetails, CalculatedMetrics, ProjectedMetrics, MonthlyData, CurrentMetrics, LongTermProjection } from '../types';
import { irr } from 'financial';

export const calculateCurrentMetrics = (clientMetrics: ClientMetrics): CalculatedMetrics => {
    const monthlyTransactions = clientMetrics.annualTransactions / 12;
    const currentCPA = clientMetrics.yearlyAdSpend / clientMetrics.annualTransactions;
    const profitPerTransaction = (clientMetrics.currentRevenue / clientMetrics.annualTransactions) * clientMetrics.profitMargin;
    const currentAnnualProfit = clientMetrics.currentRevenue * clientMetrics.profitMargin;
    const currentROI = ((currentAnnualProfit - clientMetrics.yearlyAdSpend) / clientMetrics.yearlyAdSpend) * 100;

    return {
        ...clientMetrics,
        monthlyTransactions,
        currentCPA,
        profitPerTransaction,
        currentAnnualProfit,
        currentROI
    };
};

const calculateRangePercentage = (certainty: number): number => {
    // Linear interpolation between 50% (certainty=1) and 5% (certainty=10)
    return 0.5 - (0.45 * (certainty - 1) / 9);
};

export const calculateProjectedMetrics = (
    currentMetrics: CalculatedMetrics,
    projectDetails: ProjectDetails,
    rangePercentage: number = 0.2
): ProjectedMetrics => {
    const monthlyData: MonthlyData[] = [];
    const monthlyProjectCost = projectDetails.projectBudget / projectDetails.projectTimeline;

    // Calculate range percentage based on certainty
    const rangePercentageCalculated = calculateRangePercentage(projectDetails.certainty);

    // Calculate monthly improvement rate if growing
    const totalImprovementDuration = projectDetails.improvementEndMonth - projectDetails.improvementStartMonth + 1;
    const monthlyImprovementRate = projectDetails.isImprovementGrowing
        ? (projectDetails.finalRoasImprovement - projectDetails.initialRoasImprovement) / (totalImprovementDuration - 1)
        : 0;

    let cumulativeAdditionalProfit = 0;
    let cumulativeAdditionalProfitLow = 0;
    let cumulativeAdditionalProfitHigh = 0;
    let breakEvenMonth = -1;
    let breakEvenMonthLow = -1;
    let breakEvenMonthHigh = -1;
    let month = 1;
    let allBreakEvenPointsFound = false;
    let latestBreakEven = 0;

    // Arrays to store projected values
    const projectedCPA: number[] = [];
    const projectedCPALow: number[] = [];
    const projectedCPAHigh: number[] = [];
    const projectedTransactions: number[] = [];
    const projectedTransactionsLow: number[] = [];
    const projectedTransactionsHigh: number[] = [];

    // Calculate until we have enough data after all break-even points
    while (month <= projectDetails.projectTimeline || !allBreakEvenPointsFound || month <= latestBreakEven + 3) {
        // Calculate improvement for this month
        const improvement = projectDetails.isImprovementGrowing
            ? (month / projectDetails.projectTimeline) * projectDetails.finalRoasImprovement
            : projectDetails.finalRoasImprovement;

        const roasMultiplier = 1 + improvement;
        const monthlyCPA = currentMetrics.currentCPA / roasMultiplier;
        const monthlyTransactions = (currentMetrics.yearlyAdSpend / 12) / monthlyCPA;
        
        // Calculate range metrics
        const improvementLow = improvement * (1 - rangePercentageCalculated);
        const improvementHigh = improvement * (1 + rangePercentageCalculated);
        
        const roasMultiplierBest = 1 + improvementHigh;
        const roasMultiplierWorst = 1 + improvementLow;
        
        const monthlyCPABest = currentMetrics.currentCPA / roasMultiplierBest;
        const monthlyCPAWorst = currentMetrics.currentCPA / roasMultiplierWorst;
        
        const monthlyTransactionsBest = (currentMetrics.yearlyAdSpend / 12) / monthlyCPABest;
        const monthlyTransactionsWorst = (currentMetrics.yearlyAdSpend / 12) / monthlyCPAWorst;
        
        // Store projected values
        projectedCPA.push(monthlyCPA);
        projectedCPALow.push(monthlyCPABest);
        projectedCPAHigh.push(monthlyCPAWorst);
        projectedTransactions.push(monthlyTransactions);
        projectedTransactionsLow.push(monthlyTransactionsWorst);
        projectedTransactionsHigh.push(monthlyTransactionsBest);
        
        const additionalTransactions = monthlyTransactions - currentMetrics.monthlyTransactions;
        const additionalTransactionsWorst = monthlyTransactionsWorst - currentMetrics.monthlyTransactions;
        const additionalTransactionsBest = monthlyTransactionsBest - currentMetrics.monthlyTransactions;
        
        const additionalProfit = additionalTransactions * currentMetrics.profitPerTransaction;
        const additionalProfitWorst = additionalTransactionsWorst * currentMetrics.profitPerTransaction;
        const additionalProfitBest = additionalTransactionsBest * currentMetrics.profitPerTransaction;
        
        cumulativeAdditionalProfit += additionalProfit;
        cumulativeAdditionalProfitLow += additionalProfitWorst;
        cumulativeAdditionalProfitHigh += additionalProfitBest;

        const cumulativeCost = month * monthlyProjectCost;

        // Check for break-even points
        if (breakEvenMonth === -1 && (cumulativeAdditionalProfit - cumulativeCost) >= projectDetails.projectBudget) {
            breakEvenMonth = month;
        }
        if (breakEvenMonthLow === -1 && (cumulativeAdditionalProfitLow - cumulativeCost) >= projectDetails.projectBudget) {
            breakEvenMonthLow = month;
        }
        if (breakEvenMonthHigh === -1 && (cumulativeAdditionalProfitHigh - cumulativeCost) >= projectDetails.projectBudget) {
            breakEvenMonthHigh = month;
        }

        // Update allBreakEvenPointsFound flag
        allBreakEvenPointsFound = (
            breakEvenMonth !== -1 && 
            breakEvenMonthLow !== -1 && 
            breakEvenMonthHigh !== -1
        );

        // Update latest break-even point
        latestBreakEven = Math.max(
            breakEvenMonth === -1 ? 0 : breakEvenMonth,
            breakEvenMonthLow === -1 ? 0 : breakEvenMonthLow,
            breakEvenMonthHigh === -1 ? 0 : breakEvenMonthHigh
        );

        monthlyData.push({
            month,
            projectedCost: monthlyProjectCost,
            cumulativeCost: cumulativeCost,
            projectedReturn: additionalProfit,
            cumulativeReturn: cumulativeAdditionalProfit,
            transactions: monthlyTransactions,
            transactionsLow: monthlyTransactionsWorst,
            transactionsHigh: monthlyTransactionsBest,
            cpa: monthlyCPA,
            cpaLow: monthlyCPAWorst,
            cpaHigh: monthlyCPABest,
            additionalProfit,
            additionalProfitLow: additionalProfitWorst,
            additionalProfitHigh: additionalProfitBest,
            cumulativeProfit: cumulativeAdditionalProfit - cumulativeCost,
            cumulativeProfitLow: cumulativeAdditionalProfitLow - cumulativeCost,
            cumulativeProfitHigh: cumulativeAdditionalProfitHigh - cumulativeCost
        });

        month++;
    }

    // Calculate additional metrics
    const additionalAnnualTransactions = (projectedTransactions[projectedTransactions.length - 1] - currentMetrics.monthlyTransactions) * 12;
    const additionalAnnualTransactionsLow = (projectedTransactionsLow[projectedTransactionsLow.length - 1] - currentMetrics.monthlyTransactions) * 12;
    const additionalAnnualTransactionsHigh = (projectedTransactionsHigh[projectedTransactionsHigh.length - 1] - currentMetrics.monthlyTransactions) * 12;

    const additionalAnnualProfit = additionalAnnualTransactions * currentMetrics.profitPerTransaction;
    const additionalAnnualProfitLow = additionalAnnualTransactionsLow * currentMetrics.profitPerTransaction;
    const additionalAnnualProfitHigh = additionalAnnualTransactionsHigh * currentMetrics.profitPerTransaction;

    const projectedAnnualProfit = currentMetrics.currentAnnualProfit + additionalAnnualProfit;
    const projectedAnnualProfitLow = currentMetrics.currentAnnualProfit + additionalAnnualProfitLow;
    const projectedAnnualProfitHigh = currentMetrics.currentAnnualProfit + additionalAnnualProfitHigh;

    const projectedROI = ((projectedAnnualProfit - currentMetrics.currentAnnualProfit) / projectDetails.projectBudget) * 100;
    const projectedROILow = ((projectedAnnualProfitLow - currentMetrics.currentAnnualProfit) / projectDetails.projectBudget) * 100;
    const projectedROIHigh = ((projectedAnnualProfitHigh - currentMetrics.currentAnnualProfit) / projectDetails.projectBudget) * 100;

    // Calculate IRR
    const cashFlows = [-projectDetails.projectBudget];
    const cashFlowsLow = [-projectDetails.projectBudget];
    const cashFlowsHigh = [-projectDetails.projectBudget];
    
    monthlyData.forEach(data => {
        cashFlows.push(data.additionalProfit);
        cashFlowsLow.push(data.additionalProfitLow);
        cashFlowsHigh.push(data.additionalProfitHigh);
    });

    const annualizedIRR = irr(cashFlows) * 12 * 100;  // Convert monthly to annual and to percentage
    const annualizedIRRLow = irr(cashFlowsLow) * 12 * 100;
    const annualizedIRRHigh = irr(cashFlowsHigh) * 12 * 100;

    return {
        monthlyData,
        projectedTransactions,
        projectedTransactionsLow,
        projectedTransactionsHigh,
        projectedCPA,
        projectedCPALow,
        projectedCPAHigh,
        additionalAnnualTransactions,
        additionalAnnualTransactionsLow,
        additionalAnnualTransactionsHigh,
        additionalAnnualProfit,
        additionalAnnualProfitLow,
        additionalAnnualProfitHigh,
        projectedAnnualProfit,
        projectedAnnualProfitLow,
        projectedAnnualProfitHigh,
        projectedROI,
        projectedROILow,
        projectedROIHigh,
        breakEvenMonth: breakEvenMonth,
        breakEvenMonthLow: breakEvenMonthLow,
        breakEvenMonthHigh: breakEvenMonthHigh,
        annualizedIRR,
        annualizedIRRLow,
        annualizedIRRHigh,
        rangePercentage: rangePercentageCalculated
    };
};

export const calculateLongTermProjection = (
    currentMetrics: CalculatedMetrics,
    projectDetails: ProjectDetails,
): LongTermProjection => {
    const yearLabels = ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'];
    const yearlyRevenueWithInvestment: number[] = [];
    const yearlyRevenueWithoutInvestment: number[] = [];

    // Base yearly metrics
    const yearlyAdSpend = currentMetrics.yearlyAdSpend;
    const baseYearlyRevenue = currentMetrics.currentRevenue;
    const improvedCPA = currentMetrics.currentCPA / (1 + projectDetails.finalRoasImprovement);

    // Calculate growth without investment (just organic growth)
    let currentRevenueWithoutInvestment = baseYearlyRevenue;
    for (let year = 0; year < 5; year++) {
        yearlyRevenueWithoutInvestment.push(currentRevenueWithoutInvestment);
        // Assume 5% organic growth
        currentRevenueWithoutInvestment *= 1.05;
    }

    // Calculate growth with investment and reinvestment
    let currentRevenueWithInvestment = baseYearlyRevenue;
    let currentAdSpend = yearlyAdSpend;
    
    for (let year = 0; year < 5; year++) {
        // Calculate additional transactions from improved CPA
        const additionalTransactions = (currentAdSpend / improvedCPA) - (currentAdSpend / currentMetrics.currentCPA);
        const additionalRevenue = additionalTransactions * (currentMetrics.currentRevenue / (yearlyAdSpend / currentMetrics.currentCPA));
        
        // Add additional revenue to current revenue
        currentRevenueWithInvestment += additionalRevenue;
        yearlyRevenueWithInvestment.push(currentRevenueWithInvestment);

        // Reinvest additional profit into marketing for next year
        const additionalProfit = additionalTransactions * currentMetrics.profitPerTransaction;
        currentAdSpend += additionalProfit;
    }

    return {
        yearlyRevenueWithInvestment,
        yearlyRevenueWithoutInvestment,
        yearLabels
    };
};
